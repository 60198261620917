import React, { FC, useState } from "react";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import "./index.css";
import { ReactComponent as MelonLogoTurqoise } from "./melon_logo_turqoise.svg";
import { animated, useSpring } from "react-spring";

const App: FC = () => {
    const [watching, setWatching] = useState(false);

    const styles = useSpring({
        top: watching ? "0%" : "50%",
        width: watching ? "25%" : "92%",
        transform: watching ? "translate(-50%, 0%)" : "translate(-50%, -50%)",
        marginTop: watching ? "0.5rem" : "0rem",
    });

    return (
        <div className="relative min-h-screen flex flex-col-reverse md:flex-col">
            <nav className="flex justify-between p-4">
                <button
                    className="text-meloncyan hover:text-melonsilver uppercase text-4xl space-grotesk"
                    onClick={() => setWatching(!watching)}
                >
                    {watching ? "Back" : "Watch Reel"}
                </button>
                <a
                    className="text-meloncyan hover:text-melonsilver uppercase text-4xl space-grotesk"
                    href="mailto:hi@melonverse.com"
                >
                    Contact
                </a>
            </nav>

            <animated.div
                style={styles}
                className="absolute left-1/2 cursor-pointer"
                onClick={() => setWatching(!watching)}
            >
                <MelonLogoTurqoise className="w-full h-auto" />
            </animated.div>

            <div
                className={`absolute top-0 left-0 w-full h-full -z-10 ${
                    !watching ? "duotone" : ""
                }`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 0, height: 0 }}
                >
                    <defs>
                        <filter id="duotone" colorInterpolationFilters="sRGB">
                            <feColorMatrix
                                type="matrix"
                                result="grayscale"
                                values="1 0 0 0 0
                                    1 0 0 0 0
                                    1 0 0 0 0
                                    0 0 0 1 0
                                "
                            ></feColorMatrix>
                            <feComponentTransfer
                                colorInterpolationFilters="sRGB"
                                result="duotone"
                            >
                                <feFuncR
                                    type="table"
                                    tableValues="0.05882352941176470.6980392156862745"
                                ></feFuncR>
                                <feFuncG
                                    type="table"
                                    tableValues="0.0117647058823529 0.7294117647058824"
                                ></feFuncG>
                                <feFuncB
                                    type="table"
                                    tableValues="0.1411764705882353 0.7607843137254902"
                                ></feFuncB>
                                <feFuncA
                                    type="table"
                                    tableValues="0 1"
                                ></feFuncA>
                            </feComponentTransfer>
                        </filter>
                    </defs>
                </svg>
                <ReactPlayer
                    width={"inherit"}
                    height={"inherit"}
                    className="player"
                    url="https://videodelivery.net/62d6cb59e461deed2727126b87b3e2b2/manifest/video.m3u8"
                    muted={!watching}
                    playing
                    loop
                />
            </div>
        </div>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
